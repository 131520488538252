import React from "react"
import { Col, Form, Row } from "antd"
import { useTranslation } from "react-i18next"
import usePaymentFormItems from "../forms/usePaymentFormItems"

const SignUpStepCompileBillingProfileForm = ({
  initialValues,
  onFinish,
  formRef,
}) => {
  const { t } = useTranslation()
  const formItems = usePaymentFormItems()

  return (
    <Form
      ref={formRef}
      initialValues={initialValues}
      onFinish={onFinish}
      layout={"vertical"}
      className="payment-form"
    >
      <Row justify="center">
        <Col>
          <div className="sign-up-step-header" style={{ textAlign: "center" }}>
            <h1>{t("form:signUpStep.completeProfile.title")}</h1>
            <p>{t("form:signUpStep.completeProfile.description")}</p>
          </div>
        </Col>
      </Row>
      {formItems}
    </Form>
  )
}

export default SignUpStepCompileBillingProfileForm
