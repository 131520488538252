import React from "react"
import { Col, Form, Row } from "antd"
import { useTranslation } from "react-i18next"
import PlanSelector from "../customFormItems/PlanSelector"

const SignUpStepChoosePlanForm = ({
  initialValues,
  onFinish,
  formRef,
  coupon,
}) => {
  const { t } = useTranslation()

  return (
    <Form
      ref={formRef}
      initialValues={initialValues}
      onFinish={onFinish}
      layout={"vertical"}
    >
      <Row justify="center">
        <Col>
          <div className="sign-up-step-header">
            <h1>{t("form:signUpStep.choosePlan.title")}</h1>
            <p>{t("form:signUpStep.choosePlan.description")}</p>
          </div>
        </Col>
      </Row>
      <Form.Item
        label={t("form:label.selectPlan")}
        name="plan"
        rules={[
          {
            required: true,
            message: t("form:error.selectAPlan"),
          },
        ]}
      >
        <PlanSelector coupon={coupon} />
      </Form.Item>
    </Form>
  )
}

export default SignUpStepChoosePlanForm
