import React, { useEffect, useMemo, useState } from "react"
import { Col, Divider, Form, Row, Space, Spin } from "antd"
import { useTranslation } from "react-i18next"
import { FREE } from "../../enums/Plans"
import CouponSelector from "../customFormItems/CouponSelector"
import { SUBSCRIPTION } from "../../enums/ItemTypes"
import {
  devLog,
  formatPrice,
  pushBeginCheckoutEvent,
  uiHandleError,
} from "../../utils"
import ErrorScreen from "../ErrorScreen"
import usePaymentFormItems from "../forms/usePaymentFormItems"
import useDropin from "../../hooks/useDropin"
import { PERCENTAGE } from "../../enums/DiscountTypes"
import { THREED_SECURE_FAILED } from "../../../functions/lib/error-codes"
import { CheckCircleFilled } from "@ant-design/icons"

const subscriptionPlanPrice =
  process.env.GATSBY_BRAINTREE_SUBSCRIPTION_PLAN_PRICE || "199.00"

const SignUpStepPaymentForm = ({
  initialValues,
  onFinish,
  formRef,
  coupon,
}) => {
  const { t } = useTranslation()

  const [selectedCoupon, setSelectedCoupon] = useState(coupon)
  const [finalPrice, setFinalPrice] = useState(0)

  const formItems = usePaymentFormItems()

  const { loading, error, dropinInstance, threeDSecureInstance } = useDropin(
    "#dropin-container",
    subscriptionPlanPrice,
    false
  )

  useEffect(() => {
    if (loading) {
      return
    }

    if (error) {
      return
    }

    let _discount = 0
    let _finalPrice = Number(subscriptionPlanPrice)

    if (selectedCoupon) {
      const { value, percentage, discount_type } = selectedCoupon.criterion
      if (discount_type === PERCENTAGE) {
        _discount = _finalPrice * percentage
      } else {
        _discount = value
      }
      _finalPrice = Math.max(0, _finalPrice - _discount)
    }

    setFinalPrice(_finalPrice)
  }, [selectedCoupon, loading, error])

  useEffect(() => {
    pushBeginCheckoutEvent({
      id: "0",
      price:
        Number(process.env.GATSBY_BRAINTREE_SUBSCRIPTION_PLAN_PRICE) || 199,
      name: "Abbonamento annuale",
    })
  }, [])

  const priceLabel = useMemo(() => {
    if (loading) {
      return <Spin spinning />
    }

    const price = Number(subscriptionPlanPrice)

    if (price !== finalPrice) {
      return (
        <span>
          <span className="stroked-price">{formatPrice(price)}</span>
          <span className="final-price">{formatPrice(finalPrice)}</span>
        </span>
      )
    }

    return <span>{formatPrice(price)}</span>
  }, [loading, error, finalPrice])

  const handleSubmit = values => {
    if (initialValues.plan === FREE) {
      return onFinish()
    }

    dropinInstance.requestPaymentMethod(
      {
        threeDSecure: {
          amount: subscriptionPlanPrice,
          challengeRequested: true,
        },
      },
      (requestPaymentMethodErr, payload) => {
        if (requestPaymentMethodErr) {
          // TODO handle error
          return uiHandleError({ error: requestPaymentMethodErr })
        }

        if (requestPaymentMethodErr) {
          return uiHandleError({ error: requestPaymentMethodErr })
        }

        if (payload.type === "CreditCard") {
          if (!payload.liabilityShifted && !payload.liabilityShiftPossible) {
            return uiHandleError({
              error: new Error(THREED_SECURE_FAILED),
            })
          } else {
            onFinish({
              ...values,
              paymentMethodNonce: payload.nonce,
              deviceData: payload.deviceData,
              finalPrice,
            })
          }
        } else {
          onFinish({
            ...values,
            paymentMethodNonce: payload.nonce,
            deviceData: payload.deviceData,
            finalPrice,
          })
        }
      }
    )
  }

  useEffect(() => {
    if (initialValues.plan === FREE) {
      formRef.current.submit()
    }
  }, [formRef.current])

  return (
    <Form
      ref={formRef}
      initialValues={initialValues}
      onFinish={handleSubmit}
      layout={"vertical"}
    >
      <Row justify="center">
        <Col xs={22} sm={22} md={20} lg={20} xl={12} xxl={12}>
          <div className="sign-up-step-header">
            <h1>{t("form:signUpStep.payment.title")}</h1>
            <p>{t("form:signUpStep.payment.description")}</p>
            <Divider />
            <Row justify="space-between">
              <Col>
                <Space>
                  <CheckCircleFilled
                    style={{
                      color: "#F2EA00",
                    }}
                  />
                  <b>{t("form:signUpStep.payment.annualSubscription")}</b>
                </Space>
              </Col>
              <Col>
                <b>{priceLabel}</b>
              </Col>
            </Row>
            <Divider />
          </div>
        </Col>
      </Row>

      {initialValues.plan === FREE ? null : (
        <>
          <Form.Item label={t("form:label.couponCode")} name="coupon_code">
            <CouponSelector
              placeholder={t("form:label.couponCode")}
              size="large"
              itemType={SUBSCRIPTION}
              onCouponSelect={coupon => {
                devLog({ coupon })
                setSelectedCoupon(coupon)
              }}
            />
          </Form.Item>

          {Boolean(selectedCoupon) && (
            <p
              style={{
                padding: 20,
                textAlign: "center",
                border: "1px solid orange",
                backgroundColor: "#ffe6a2",
              }}
            >
              {t("warning:subscription3DsCheckWithCouponWarning")}
            </p>
          )}

          <Spin spinning={loading}>
            <div id="dropin-container" />
            {error && <ErrorScreen error={error} />}
          </Spin>
        </>
      )}
    </Form>
  )
}

export default SignUpStepPaymentForm
